.flix-results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

// Movie Description Styles
.flix-results-movie {
  display: flex;
  color: white;
  .flix-results-movie-image {
    max-height: 500px;
    margin-right: 32px;
  }
  .flix-results-movie-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 500px;
    width: 325px;
    padding: 16px;
    background: rgba(0, 0, 0, 0.5);
  }
  .flix-results-movie-content-details {
    overflow: auto;
    font-size: 16px;
    margin-bottom: 16px;

    &-title {
      color: white;
      text-align: center;
      font-size: 26px;
    }
    &-description {
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
    &-director {
      margin-bottom: 8px;
    }
    &-cast {
    }
  }
  .flix-results-movie-content-rating {
    display: flex;
    justify-content: center;
  }
  .flix-results-movie-content-actions {
    display: flex;
    justify-content: space-between;
  }
}

.flix-results-button {
  margin-top: 32px;
}

// Modal Styles
.flix-results-trailer-modal {
  .ant-modal-content {
    background: none;
  }
  .ant-modal-body {
    padding: 0;
  }
}

// Scrollbar Styles
::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}
