.flix-background {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-transition: background 1s;
  -moz-transition: background 1s;
  -o-transition: background 1s;
  transition: background 1s;
  overflow: auto;
  scrollbar-gutter: stable;
}

.flix-content {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
}

.flix-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 32px;
  padding-bottom: 20px;
  .title {
    color: white;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 100px;
    width: 460px;
    text-align: center;
    border: 15px solid white;
    margin: 10px;
  }

  .sub-title {
    font-size: 40px;
    margin: 0;
  }
}

.flix-spinner {
  background: rgba(0, 0, 0, 0.5);
  padding: 32px !important;
}
